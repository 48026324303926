<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            name="year"
            type="year"
            default="today"
            label="년도"
            v-model="searchParam.year"
          ></c-datepicker>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="grid"
          title="휴뮤일"
          tableId="grid"
          :columnSetting="false"
          :isFullScreen="false"
          :usePaging="false"
          :columns="grid.columns"
          :isExcelDown="false"
          :data="grid.data"
          selection="multiple"
          rowKey="sysHolidayId"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="추가" icon="add" @btnClicked="addrow" v-if="editable" />
              <c-btn label="삭제" icon="remove" @btnClicked="removeRow" v-if="editable" />
              <c-btn label="저장" icon="save" @btnClicked="saveRow" v-if="editable" />
              <c-btn label="검색" icon="search"  @btnClicked="getList"/>
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'holiday-manage',
  data() {
    return {
      editable: true,
      searchParam: {
        year: null,
        holidayFlag: 'C',
      },
      grid: {
        columns: [
          {
            required: true,
            name: 'holidayDt',
            field: 'holidayDt',
            label: '일자',
            align: 'center',
            type: 'date',
            style: 'width:20%',
            sortable: true,
          },
          {
            required: true,
            name: 'holidayName',
            field: 'holidayName',
            label: '휴무일 명',
            align: 'left',
            type: 'text',
            sortable: true,
          },
        ],
        data: [],
      },
      listUrl: '',
      saveUrl: '',
      deleteUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sys.holiday.list.url;
      this.saveUrl = transactionConfig.sys.holiday.save.url;
      this.deleteUrl = transactionConfig.sys.holiday.delete.url;
      this.getList();
    },
    rowRemoveSelect() {
      var _table = document.getElementsByClassName('bg-light-blue-1');
      if (_table.length > 0) {
        for(let c = 0; c <= _table.length; c++) {
          _table[c].classList.remove('bg-light-blue-1');
        }
      }
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    addrow() {
      this.grid.data.push({
        sysHolidayId: uid(),  // 휴무일 일련번호
        holidayName: '',  // 휴무일 명
        holidayDt: '',  // 휴무일
        holidayFlag: 'C',  // 휴무일 구분
        editFlag: 'C',
      })
    },
    removeRow() {
      let selectData = this.$refs['grid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getList();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.grid.data = this.$_.reject(this.grid.data, item);
              })
              this.$refs['grid'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveRow() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        let saveData = this.grid.data.filter( x => {
          return x.editFlag == 'C' || x.editFlag == 'U'
        });
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          type: 'info',
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.saveUrl;
            this.$http.type = 'POST';
            this.$http.param = saveData;
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.getList()
            });
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>
